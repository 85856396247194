.profile {
  .media {
    align-items: center !important;
  }
  .media-left {
    // width: 140px;
    // height: 140px;
    overflow: hidden;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    // vertical-align: top;
    border: 2px solid #dfdfdf;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      // border-radius: 20px !important;
    }
  }
}
// ===============================================================
// .container {
//   height: 100%;
//   width: 100%;
// }

// h1 {
//   font-family: "Pacifico", "Open Sans";
//   font-weight: lighter;
//   color: #ff8e8e;
// }

// .container {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   background-color: #eee8f8;
// }

.variants {
  display: flex;
  justify-content: center;
  align-items: center;
}

.variants > div {
  margin-right: 5px;
}

.variants > div:last-of-type {
  margin-right: 0;
}

.file {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.file > input[type="file"] {
  display: none;
}

.file > label {
  cursor: pointer;
  border-radius: 5px;
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file--uploading > label {
  color: white;
  background-color: rgb(253, 184, 19);
  border-color: rgb(253, 184, 19);
  box-shadow: none;
  // border-radius: 1px;
}

// @keyframes pulse {
//   0% {
//     color: hsl(48, 100%, 67%);
//   }
//   50% {
//     color: hsl(48, 100%, 38%);
//   }
//   100% {
//     color: hsl(48, 100%, 67%);
//   }
// }
