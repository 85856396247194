.selectColor {
  position: relative;
  padding: 10px;
  border: none;
  border-right: 10px solid #fff;
  border-left: 10px solid #0f8ef7;
  outline: none;
  width: 150px;
  border-radius: 4px;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.048);
  margin-right: 20px;
}

@media (max-width: 687px) {
  .small-screen-style {
    display: flex !important;
    justify-content: center !important;
    gap: 0 !important;

  }
}

@media (max-width: 374px) {
  .selectColor {
    width: 100%;
    margin: 0 auto 20px auto;
    display: block;
  }
  .hidden-print {
    margin: 0 auto 20px auto;
    text-align: center !important;
  }
}
