.flex-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .plus {
    font-size: 14px;
  }
}
.btn-style {
  display: flex;
  gap: 4px;
  margin-right: 10px;
  margin-bottom: 30px;
}
.inputs-search {
  display: flex;
  padding: 10px;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .form-control {
    font-size: 12px !important;
    height: 35px !important;
    width: 200px;
  }
}