@import "../../../assets/assets/scss/base/variables";
.dropd {
  .dropdown {
    display: inline-block !important;
    text-align: left !important;
    // margin-top: 5px !important;

    .dropdown-menu {
      transform: none !important;
      border: none;
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);
      padding: 15px;
      background: #17191c;
      border-radius: $border-radius-large;

      li.divider {
        border-bottom: 1px solid $grey-800;
        margin: 10px 0;
      }

      a {
        display: block;
        padding: 10px;
        font-size: 14px;
        color: $grey-500;

        &:hover {
          color: $col-white;
          background-color: transparent;
          i {
            color: $col-white;
          }
        }

        i {
          margin-right: 10px;
          font-size: 14px;
        }
      }
    }
  }
}
.dropdown-menu {
  box-shadow: (0px 2px 20px 0px rgba(0, 0, 0, 0.2));
  background: $col-white;
  top: 80%;
  position: absolute;
  right: 0;
  left: auto;
  //   @extend .br8;
  border: 1px solid $col-white;
  padding: 20px;
}

.notifications {
  background: $col-dark;
  width: 500px;

  //   @include max-screen($break-small) {
  //     width: calc(100% - 20px);
  //     margin: 0 10px;
  //   }

  li {
    border-bottom: 1px solid $dark-line-color;

    &.header {
      padding-bottom: 20px;
      color: $grey-500;
    }

    &.footer a {
      padding-top: 20px;
      padding-bottom: 0;
      color: $color-accent;

      &:hover,
      &:focus {
        background: transparent;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    i {
      font-size: 24px;
    }

    > a {
      padding: 15px 0;
      color: $grey-600;

      display: block;

      .media {
        margin-bottom: 0;
        .media-left {
          margin-right: 20px;
        }
      }

      &:hover,
      &:focus {
        background-color: rgba(255, 255, 255, 0.03);
      }
    }

    .text {
      margin-bottom: 0;
      white-space: nowrap;
      width: 88%;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }

    .timestamp {
      font-size: 13px;
      color: $grey-700;
    }
  }
  .dropdown-toggle {
    color: black !important;
  }
}
