.sidebar {
  margin-top: 0;
  padding: 0;
  // .MuiAccordionSummary-contentGutters {
  //   margin: 12px 0 !important;
  // }
  // .Mui-expanded {
  //   max-height: 48px !important;
  // }
  // .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  //   background-color: transparent !important;
  //   box-shadow: none !important;
  //   border: none !important;
  //   &::before {
  //     height: 0px !important;
  //   }
  // }

 
  ul {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
    .active {
      .list {
        background-color: rgba(221, 221, 221, 0.377);
      }
    }
    a {
      color: rgba(0, 0, 0, 0.696);
      height: 100%;
      width: 100%;

      .list {
        margin: 0;
        width: 100%;
        font-size: 12px;
        padding: 10px 0;
        padding-left: 30px;
        cursor: pointer;

        &:hover {
          background-color: rgba(221, 221, 221, 0.377);
        }
      }
    }
  }
  .active {
    background-color: rgba(221, 221, 221, 0.377);
  }
  .side-hr {
    width: 85%;
  }
  .company-title {
    padding: 6px 14px;
    border-radius: 20px;
    background-color: #fdb813;
    color: white;
  }
}

#left-sidebar {
  margin-top: 80px;
}

.showSidebar {
  left: 0 !important;
}
.toglleNav {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    border: none;
    background-color: transparent;
    // font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .toglleNav {
    display: none;
  }
}
