// index.scss

.image-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000000 !important;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-popup-content {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 80%;
}

.popup-close {
  position: absolute;
  top: 30px;
  right: 27px;
  font-size: 15px;
  cursor: pointer;
  color: #000;
  border: 1px solid #fff;
  background-color: #FFF;
  border-radius: 5px;
  padding:0px 5px;
  z-index: 2; // Ensure the button is on top
}

.image-container {
  margin-top: 30px;
}

.image-container img {
  width: 100%;
  height: auto;
}
