.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
  }
  .text-wrap {
    word-wrap: break-word;
    color: black;
  }
  .fit {
    width: 100%;
    text-align: center;
  }
 
  
  .popup-submit {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 30px;
    width: 30%;
    max-height: 45%;
    overflow: hidden;
    overflow-y: scroll;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .popup-submit::-webkit-scrollbar {
    width: 0.1em; /* Width of the invisible scrollbar */
  }
  
  .popup-submit::-webkit-scrollbar-thumb {
    background-color: transparent; /* Color of the thumb */
  }
  .popup-submit::-webkit-scrollbar-track {
    background-color: transparent; /* Color of the track */
  }
  .style-btn-style {
    width: 150px;
  }
  .style-btn-style:hover {
    width: 150px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .color-btn,
  .color-btn:hover {
    background-color: white;
    color: black;
    border: 1px solid #fdb813;
  }
  .coloring-success,
  .coloring-success:hover {
    background-color: #fdb813;
    color: white;
    border: 1px solid #fdb813;
  }
  
  @media screen and (max-width: 767px) {
    .popup-submit {
      width: 95%;
      height: fit-content;
    }
  }
  



// ===========================================================
// Sample container
.main-container {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
}

// Main CSS
.check-container {
	width: 6.25rem;
	height: 7.5rem;
	// display: flex;
	// flex-flow: column;
	// align-items: center;
	// justify-content: space-between;

	.check-background {
		width: 100%;
		height: calc(100% - 1.25rem);
		background: linear-gradient(to bottom right, #fdb813, #fdb813);
		// box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
		// 	0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
		transform: scale(0.84);
		border-radius: 50%;
		animation: animateContainer 0.75s ease-out forwards 0.75s;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;

		svg {
			width: 65%;
			transform: translateY(0.25rem);
			stroke-dasharray: 80;
			stroke-dashoffset: 80;
			animation: animateCheck 0.35s forwards 1.25s ease-out;
		}
	}

}

@keyframes animateContainer {
	0% {
		opacity: 0;
		transform: scale(0);
		box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
			0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
	}
	25% {
		opacity: 1;
		transform: scale(0.9);
		box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
			0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
	}
	43.75% {
		transform: scale(1.15);
		box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset,
			0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
	}
	62.5% {
		transform: scale(1);
		box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
			0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
	}
	81.25% {
		box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
			0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
	}
	100% {
		opacity: 1;
		box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
			0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
	}
}

@keyframes animateCheck {
	from {
		stroke-dashoffset: 80;
	}
	to {
		stroke-dashoffset: 0;
	}
}

@keyframes animateShadow {
	0% {
		opacity: 0;
		width: 100%;
		height: 15%;
	}
	25% {
		opacity: 0.25;
	}
	43.75% {
		width: 40%;
		height: 7%;
		opacity: 0.35;
	}
	100% {
		width: 85%;
		height: 15%;
		opacity: 0.25;
	}
}

