.icon-login {
  font-size: 18px !important;
  color: #666666 !important;
}
.space {
  width: 20px;
}
@media (min-width: 1200px) {
  .space {
    display: none;
  }
}

.between {
  gap: 20px;
}
