.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
.text-wrap {
  word-wrap: break-word;
  color: black;
}
.fit {
  width: 100%;
  text-align: center;
}

.popup {
  display: flex;
  flex-direction: column;
  // justify-content: space-around;
  align-items: center;
  background-color: white;
  padding: 30px;
  width: 60%;
  max-height: 85%;
  overflow: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.popup::-webkit-scrollbar {
  width: 0.1em; /* Width of the invisible scrollbar */
}

.popup::-webkit-scrollbar-thumb {
  background-color: transparent; /* Color of the thumb */
}
.popup::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the track */
}
.style-btn {
  width: 100px;
}
.style-btn:hover {
  width: 100px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.color-btn,
.color-btn:hover {
  background-color: white;
  color: black;
  border: 1px solid #fdb813;
}
.coloring,
.coloring:hover {
  background-color: #fdb813;
  color: black;
  border: 1px solid #fdb813;
}

@media screen and (max-width: 767px) {
  .popup {
    width: 95%;
    height: fit-content;
  }
}
