.parent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  text-align: center !important ;
}
.center {
  display: flex;
  justify-content: center;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40vh;
  width: 100%;
  text-align: center !important ;
}
