.header {
  display: flex;
  justify-content: space-between;
}

.accept-user {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  .buttons {
    display: flex;
    gap: 10px;
    .btn {
      width: 80px;
    }
  }
}
.user-img {
  width: 140px !important;
  height: 140px;
  object-fit: cover;
}
@media (max-width: 400px) {
  .header {
    display: block;
    justify-content: space-between;
  }
  
  .accept-user {
    display: block !important;
    text-align: center;
    .buttons {
      justify-content: center;
    }
  }
}
